import { useNavigate } from "react-router-dom";
import back from "../../assets/images/iconBack.svg";

export default function BackIcon() {
  const navigate = useNavigate();
  return (
    <div className="back-icon" onClick={() => navigate(-1)}>
      <img src={back} alt="back" />
    </div>
  );
}
